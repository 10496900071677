<template>
  <v-menu
    v-if="$route.path !== '/'"
    v-model="map.legendMenu"
    content-class="menu"
    :close-on-content-click="false"
    :close-on-click="false"
    :nudge-width="100"
    top
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="RMwhite"
        class="pa-0 button-legend"
        min-height="40"
        min-width="40"
        width="40"
        height="40"
        v-bind="attrs"
        :disabled="!isLegendActive"
        style="pointer-events: auto"
        v-on="on"
        @click="layerLegend(); keepClose = false;"
      >
        <SvgIcon
          :title="$t('BottomSection.legend')"
          :path="legendSvg"
        />
      </v-btn>
    </template>

    <v-card
      v-if="isLegendActive"
      elevation="0"
      min-width="200"
      :height="mobileBreakpoints() ? '200' : '300'"
      tile
    >
      <v-card-title class="pa-1 justify-end">
        <v-icon
          color="red"
          size="20"
          @click="map.legendMenu = false; keepClose = true;"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="containerTextLegend">
        <v-overlay
          :value="overlay"
          absolute
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
        <div v-if="isRaster">
          <div class="legenda">
            <v-row class="font-weight-bold text-subtitle-2">
              {{ $t(`${rasterLayerName}.label`) }}{{ $t(`${rasterLayerName}.udm`) }}
            </v-row>
            <v-alert
              v-if="!rasterHasLegend"
              text
              dense
              type="info"
              color="#0da344"
              class="mt-3"
            >
              {{ $t('BottomSection.noLegendAvailable') }}
            </v-alert>
            <v-row class="flex-no-wrap">
              <v-col
                cols="1"
                class="minColWidth"
              >
                <div
                  v-for="(gradient, index) in rasterGradients.slice().reverse()"
                  :key="index"
                >
                  <div :style="`height: 40px; width: 20px; background: linear-gradient(to top, ${gradient.join(', ')})`" />
                </div>
              </v-col>

              <v-col>
                <v-list
                  dense
                  flat
                  class="pa-0"
                >
                  <v-list-item-group
                    v-model="geoserver_data.rasterLegend_selectedLabel"
                    multiple
                  >
                    <v-list-item
                      v-for="(label, index) in rasterLabels.slice().reverse()"
                      :key="index"
                      class="pr-0"
                      @input="setEnv(index)"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-content
                          v-if="rasterLabels[1]"
                          class="body-2"
                        >
                          <v-list-item-title
                            style="overflow-x: auto; text-overflow: clip;"
                          >
                            {{ label }}
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action v-if="rasterClickable[index]">
                          <v-icon
                            v-if="active"
                            color="grey lighten-1"
                            small
                          >
                            mdi-eye-off
                          </v-icon>

                          <v-icon
                            v-else
                            small
                          >
                            mdi-eye
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- <v-divider /> -->
        <div v-if="isVector">
          <div
            v-for="(name, i) in vectorLayerName"
            :key="i"
            class="legenda"
          >
            <v-row class="font-weight-bold text-subtitle-2">
              {{ $t(`${name}.label`) }}{{ $t(`${name}.udm`) }}
            </v-row>
            <v-row class="flex-no-wrap">
              <v-col
                cols="1"
                class="minColWidth"
              >
                <div
                  v-for="(color, n) in vectorColorsArray[i]"
                  :key="n"
                  style="padding: 10px 0px;"
                >
                  <div :style="`height: 20px; width: 20px; background-color:${color}; border: 2px solid ${vectorStrokesArray[i][n]}; border-radius: 3px`" />
                </div>
              </v-col>
              <v-col>
                <v-list
                  class="pa-0"
                  dense
                  flat
                >
                  <v-list-item-group
                    v-model="geoserver_data.vectorLegend_selectedLabel[name.split('_serviceID:')[0]]"
                    multiple
                  >
                    <v-list-item
                      v-for="(label, index) in vectorLabelsArray[i].slice().reverse()"
                      :key="index"
                      class="pr-0"
                      @input="setParamRange(name.split('_serviceID:')[0], i, vectorClickable[name.split('_serviceID:')[0]][index])"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-content
                          v-if="vectorLabels.length > 0"
                          class="body-2"
                        >
                          <v-list-item-title
                            style="overflow-x: auto; text-overflow: clip;"
                          >
                            {{ label }}
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action v-if="vectorClickable[name.split('_serviceID:')[0]][index]">
                          <v-icon
                            v-if="active"
                            color="grey lighten-1"
                            small
                          >
                            mdi-eye-off
                          </v-icon>

                          <v-icon
                            v-else
                            small
                          >
                            mdi-eye
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import SvgIcon from '@/components/utils/SvgIcon.vue';
import { mapActions, mapState } from 'vuex';
import { svgLegend } from '@/svg.js';

export default {
  components: {
    SvgIcon
  },
  props: {
  },
  data: () => ({
    env: '',
    rasterLayerName: '',
    legendSvg: svgLegend,
    overlay: false,
    rasterHasLegend: true,
    keepClose: false,
    paramName: {},
    vectorClickable: {},
    rasterClickable: [],
    vectorLayerName: [],
    envValues: [],
    rasterGradients: [],
    rasterLabels: [],
    vectorLabels: [],
    vectorColorsArray: [],
    vectorStrokesArray: [],
    vectorLabelsArray: [],
    vectorParamsArray: []
  }),
  computed: {
    ...mapState(['drawer', 'geoserver_data', 'map', 'app_user', 'file_transfer']),
    isLegendActive() {
      return this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.layer_type === 'RASTER' || layer.values_.layer_type === 'VECTOR' || layer.values_.layer_type === 'REMOVE');
    },
    isRaster() {
      return this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.layer_type === 'RASTER');
    },
    isVector() {
      return this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.layer_type === 'VECTOR' || layer.values_.layer_type === 'REMOVE');
    }
    // dynamicGradient() {
    //   return `background: linear-gradient(to top, ${this.everyColors.slice(1).join(', ')}); height: 100%; width: 25px;border-radius: 5px;`;
    // }
  },
  watch: {
    $route() {},
    'map.reloadLegend': {
      async handler(newValue) {
        if (newValue) {
          if (this.isLegendActive && !this.keepClose) {
            if (!this.mobileBreakpoints()) {
              this.map.legendMenu = true;
            }
          } else {
            this.map.legendMenu = false;
          }

          this.layerLegend();
        }
      }
    }
  },
  methods: {
    ...mapActions(['getLegend', 'loadVectorLayer']),
    isLandscape() {
      return window.innerHeight <= 600 && window.innerHeight < window.innerWidth;
    },
    mobileBreakpoints() {
      return this.$vuetify.breakpoint.name === 'xs' || this.isLandscape();
    },
    async layerLegend() {
      if (this.map.reloadLegend === this.map.legendMenu) {
        this.overlay = true;
        this.envValues = [];
        this.rasterGradients = [];
        this.rasterLabels = [];
        this.vectorLayerName = [];
        this.vectorLabelsArray = [];
        this.vectorColorsArray = [];
        this.vectorStrokesArray = [];
        this.vectorParamsArray = [];
        this.rasterClickable = [];
        let layerStyle = '';

        const layers = this.map.OLmap.getLayers().getArray().slice(1);

        if (layers.some(layer => layer.values_.layer_type === 'RASTER')) {
          layers.filter(layer => {
            if (layer.values_.layer_type === 'RASTER') {
              this.rasterLayerName = `${layer.values_.layer_name}_serviceID:${layer.values_.service_id}`;
              layerStyle = layer.values_.layer_style;
            }
          });
          await this.getLegend({
            layer_name: this.rasterLayerName.split('_serviceID:')[0],
            layer_style: layerStyle
          });

          const colormapArray = this.geoserver_data.legendArray.data.Legend[0].rules[0].symbolizers[0].Raster.colormap?.entries || [];
          this.rasterHasLegend = colormapArray.length > 0;
          colormapArray.forEach(obj => {
            if (obj.label !== undefined && !this.rasterLabels.includes(obj.label)) {
              this.rasterLabels.push(obj.label);
              this.envValues.unshift(`${obj.opacity.split('\'\'')[1]}:0`);

              if (obj.opacity !== '0.0') {
                this.rasterClickable.unshift(true);
              } else {
                this.rasterClickable.unshift(false);
              }
            }
          });

          this.rasterLabels.forEach(label => {
            const tempArray = colormapArray.filter(item => item.label === label);
            const colors = tempArray.map(item => item.color);

            if (!this.rasterGradients.some(subArray => subArray.length === colors.length && subArray.every((color, index) => color === colors[index]))) {
              this.rasterGradients.push(colors);
            }
          });
        }

        if (layers.some(layer => layer.values_.layer_type === 'VECTOR')) {
          layers.map(async layer => {
            const name = `${layer.values_.layer_name}_serviceID:${layer.values_.service_id}`;

            if (layer.values_.layer_type === 'VECTOR' && !this.vectorLayerName.includes(name)) {
              await this.getLegend({
                layer_name: layer.values_.layer_name,
                layer_style: layer.values_.layer_style
              });
              const vectorColors = [];
              const vectorStrokes = [];
              this.vectorLabels = [];
              const vectorParams = [];
              const fillOpacity = [];

              if (!this.vectorLayerName.includes(name)) {
                this.vectorLayerName.push(name);
              }

              const colormapArray = this.geoserver_data.legendArray.data.Legend[0].rules;
              colormapArray.forEach(obj => {
                if (obj.symbolizers[0].Point) {
                  if (obj.symbolizers[0].Point.graphics[0]['fill-opacity'] == 1) {
                    fillOpacity.push(!obj.name.includes('geom_property'));
                  } else {
                    fillOpacity.push(false);
                  }

                  if (obj.symbolizers[0].Point.graphics[0].fill) {
                    const color = Array.isArray(obj.symbolizers[0].Point.graphics[0].fill) ? obj.symbolizers[0].Point.graphics[0].fill : [obj.symbolizers[0].Point.graphics[0].fill];
                    vectorColors.unshift(...color);
                  } else {
                    vectorColors.push('red');
                  }

                  const colorStroke = Array.isArray(obj.symbolizers[0].Point.graphics[0].stroke) ? obj.symbolizers[0].Point.graphics[0].stroke : [obj.symbolizers[0].Point.graphics[0].stroke];
                  const stroke = obj.symbolizers[0].Point.graphics[0].stroke ? colorStroke : ['black'];
                  vectorStrokes.unshift(...stroke);

                  this.vectorLabels.push(obj.title);

                  if (obj.filter && obj.filter.includes('type_property')) {
                    vectorParams.unshift(`${obj.filter.split('\'')[1]},=`);
                    this.paramName[layer.values_.layer_name] = 'type_property';
                  } else {
                    vectorParams.unshift(obj.name);
                    this.paramName[layer.values_.layer_name] = 'standard_property';
                  }
                }

                if (obj.symbolizers[0].Polygon) {
                  this.paramName[layer.values_.layer_name] = 'standard_property';
                  const color = Array.isArray(obj.symbolizers[0].Polygon.fill) ? obj.symbolizers[0].Polygon.fill : [obj.symbolizers[0].Polygon.fill];
                  const colorStroke = Array.isArray(obj.symbolizers[0].Polygon.stroke) ? obj.symbolizers[0].Polygon.stroke : [obj.symbolizers[0].Polygon.stroke];
                  const stroke = obj.symbolizers[0].Polygon.stroke ? colorStroke : ['black'];
                  vectorColors.unshift(...color);
                  vectorStrokes.unshift(...stroke);

                  if (obj.filter && !obj.name.includes('geom_property')) {
                    if (obj.filter.split('AND').length > 1) {
                      vectorParams.unshift(obj.name);
                    } else {
                      vectorParams.unshift(`${obj.filter.split('\'')[1]},=`);
                    }
                  }

                  this.vectorLabels.push(obj.title);
                  fillOpacity.push(obj.filter && !obj.name.includes('geom_property'));
                }

                if (obj.symbolizers[0].Line) {
                  const color = Array.isArray(obj.symbolizers[0].Line.stroke) ? obj.symbolizers[0].Line.stroke : [obj.symbolizers[0].Line.stroke];
                  vectorColors.unshift(...color);
                  vectorStrokes.unshift('black');
                  this.vectorLabels.push(obj.title);
                  fillOpacity.push(!obj.name.includes('geom_property'));
                  vectorParams.unshift(obj.name);
                  this.paramName[layer.values_.layer_name] = 'standard_property';
                }
              });
              this.vectorColorsArray.push(vectorColors);
              this.vectorStrokesArray.push(vectorStrokes);
              this.vectorLabelsArray.push(this.vectorLabels);
              this.vectorParamsArray.push(vectorParams);

              if (!(layer.values_.layer_name in this.geoserver_data.vectorLegend_selectedLabel)) {
                this.geoserver_data.vectorLegend_selectedLabel[layer.values_.layer_name] = [];
              }

              this.vectorClickable[layer.values_.layer_name] = fillOpacity;
            }
          });
        }

        this.overlay = false;
      }
    },
    setEnv(index) {
      if (this.rasterClickable[index]) {
        const filteredEnv = this.envValues.filter(env => this.geoserver_data.rasterLegend_selectedLabel.includes(this.envValues.indexOf(env)));
        this.env = filteredEnv.join(';');

        this.map.OLmap.getLayers().getArray().forEach(layer => {
          if (layer.values_.layer_type === 'RASTER' && layer.values_.layer_name === this.rasterLayerName.split('_serviceID:')[0]) {
            layer.getSource().updateParams({ ENV: this.env });
          }
        });
        this.env = '';
      }
    },
    async setParamRange(layerName, i, bool) {
      if (bool) {
        const param_range = {};
        param_range[layerName] = [[], []];

        this.geoserver_data.vectorLegend_selectedLabel[layerName].forEach(item => {
          if (this.vectorParamsArray[i][item].split(',').length > 2) {
            param_range[layerName][0].push(this.vectorParamsArray[i][item].replace(',<=,', ',').replace(',<,', ','));
          } else {
            param_range[layerName][1].push(this.vectorParamsArray[i][item].replace(',=', ''));
          }
        });

        for await (const layer of this.map.OLmap.getLayers().getArray().slice(1)) {
          if (layer.values_.layer_type === 'VECTOR' && layer.values_.layer_name === layerName) {
            const paramRangeCondition = param_range[layerName][0].length !== 0 || param_range[layerName][1].length !== 0;
            this.map.vectorParamRange[layer.values_.service_id] = paramRangeCondition
              ? `[[${param_range[layerName][0].sort()}], [${param_range[layerName][1]}], "${this.paramName[layerName]}"]`
              : undefined;

            const vectorParams = {
              params: {
                GROUP: this.$route.params.id,
                LAYERS: layer.values_.layer_name,
                TIME: layer.values_.time,
                STYLES: layer.values_.layer_style,
                USERNAME: this.app_user.username,
                SERVICE_NAME: layer.values_.service_name,
                PARAM_RANGE: this.map.vectorParamRange[layer.values_.service_id]
              },
              TYPE: 'VECTOR',
              LAYER_SERVICE_ID: layer.values_.service_id,
              EXTENT: this.geoserver_data.geographicDomains[layer.values_.service_id]
            };

            layer.set('layer_type', 'REMOVE');
            await this.loadVectorLayer(vectorParams);
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.v-menu__content {
  z-index: 3!important;
}

@media (max-device-width:599px), (max-device-height:599px) and (orientation: landscape){
  .button-legend {
    margin: 4px!important;
  }
  .menu {
    max-width: calc(100% - 32px);
    width:min-content;
    max-height: 200px;
  }
  .v-card__title {
    font-size: 16px!important;
  }
}
.transparent-bg {
  background-color: transparent;
  scrollbar-width: thin;
}
.flex-no-wrap {
  flex-wrap: nowrap;
  padding-top: 8px;
}
.containerTextLegend {
  position: relative;
  overflow-y: auto;
  height: calc(100% - 29px);
}
.row {
  margin: 0px!important;
}
.flex-no-wrap > .col{
  padding: 0px!important;
}
.containerTextLegend div .legenda:nth-of-type(1){
  margin-top: 0px;
}
.containerTextLegend > div:nth-child(1){
  margin-top: 0px;
}
.containerTextLegend > div:nth-child(2){
  margin-top: 0px;
}
.containerTextLegend > div {
  margin-top: 32px;
}
.legenda {
  margin-top: 32px;
}
.minColWidth {
  min-width: 20px;
}
.v-card__title {
  font-size: 18px;
}
</style>
